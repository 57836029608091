import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { DiscussionEmbed } from "disqus-react"
import BackgroundImage from 'gatsby-background-image'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: markdownRemark.frontmatter.path },
  }

  let featuredImgFluid = frontmatter.featuredImage && frontmatter.featuredImage.childImageSharp.fluid

  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        description={frontmatter.metaDescription}
        image={featuredImgFluid ? featuredImgFluid.src : undefined}
      />
      <div className="blog-post-container">
        <article className="post">
          {!featuredImgFluid && (
            <div className="post-thumbnail no-image">
              <div className="post-title-wrapper">
                <div className="post-title-container">
                  <div className="post-meta">{frontmatter.date}</div>
                  <h1 className="post-title">{frontmatter.title}</h1>
                </div>
              </div>
            </div>
          )}
          {!!featuredImgFluid && (
             <BackgroundImage
               Tag="section"
               className="post-thumbnail"
               fluid={featuredImgFluid}
             >
            <div className="post-title-wrapper">
              <div className="post-title-container">
                <div className="post-meta">{frontmatter.date}</div>
                <h1 className="post-title">{frontmatter.title}</h1>
              </div>
            </div>
             </BackgroundImage>
          )}
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </article>
        <div className="blog-post-comments">
          <DiscussionEmbed {...disqusConfig} />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "DD. MMMM YYYY")
        path
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1140, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        metaDescription
      }
    }
  }
`